import { TRACKING_EVENTS } from '@core/tracking/constants';
import {
    GREAT_BRITAIN_COUNTRY_KEY,
    EUROPE_CONTINENT_KEY,
    CURRENCY_SS_KEY,
    COUNTRY_SS_KEY,
} from '../constants';

// Hooks
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { track } from '@core/tracking';
import { getCountryFromSS, getCurrencyFromSS } from '../utils';

// Redux
import { setCountry, setCurrency } from '@app/pricing/models/pricing';

// Types
import type { GeoLocation } from '../types';
import { Currency } from '../types';

let trackingCompleted = false;

export const useInitializeCurrency = () => {
    const dispatch = useDispatch();

    const handleSetDefaults = useCallback(() => {
        dispatch(setCurrency(Currency.USD));
        sessionStorage.setItem(CURRENCY_SS_KEY, Currency.USD);

        dispatch(setCountry('US'));
        sessionStorage.setItem(COUNTRY_SS_KEY, 'US');
    }, [dispatch]);

    const handleError = useCallback(() => {
        handleSetDefaults();

        if (!trackingCompleted) {
            // Track geodetection failure
            track(TRACKING_EVENTS.signup.geolocation.fetched, {
                success: false,
            });

            trackingCompleted = true;
        }
    }, [handleSetDefaults]);

    const handleSetFetchedData = useCallback(
        (options: GeoLocation) => {
            try {
                // Set currency
                const isGreatBritain = options.country === GREAT_BRITAIN_COUNTRY_KEY;
                const isEurope = options.continent === EUROPE_CONTINENT_KEY;

                if (isGreatBritain) {
                    dispatch(setCurrency(Currency.GBP));
                    sessionStorage.setItem(CURRENCY_SS_KEY, Currency.GBP);
                } else if (isEurope) {
                    dispatch(setCurrency(Currency.EUR));
                    sessionStorage.setItem(CURRENCY_SS_KEY, Currency.EUR);
                } else {
                    dispatch(setCurrency(Currency.USD));
                    sessionStorage.setItem(CURRENCY_SS_KEY, Currency.USD);
                }

                // Set country
                dispatch(setCountry(options.country));
                sessionStorage.setItem(COUNTRY_SS_KEY, options.country);

                if (!trackingCompleted) {
                    // Track geodetection success
                    track(TRACKING_EVENTS.signup.geolocation.fetched, {
                        success: true,
                    });

                    trackingCompleted = true;
                }
            } catch (error) {
                handleError();
            }
        },
        [dispatch, handleError],
    );

    useEffect(() => {
        const savedCurrency = getCurrencyFromSS();
        const savedCountry = getCountryFromSS();

        if (!savedCurrency || !savedCountry) {
            try {
                // @ts-ignore
                window.geofeed = (options: GeoLocation) => {
                    handleSetFetchedData(options);
                };

                // Call geo-location JSON service
                const geolocationScript = document.createElement('script');
                geolocationScript.setAttribute(
                    'src',
                    'https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location/geofeed',
                );
                document.head.appendChild(geolocationScript);

                // Handle Script Error
                geolocationScript.onerror = () => {
                    handleError();
                };
            } catch (error) {
                handleError();
            }
        } else {
            dispatch(setCurrency(savedCurrency));
            dispatch(setCountry(savedCountry));
        }
    }, [dispatch, handleError, handleSetFetchedData]);
};
