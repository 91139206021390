import React from 'react';

// Types
import { TextAlign } from '@ui/types';
import { cn } from '@utils/cn';

export interface Props {
    children: React.ReactNode;
    align?: TextAlign;
    className?: string;
}

const Subtitle: React.FC<Props> = ({ children, align = 'center', className }) => {
    return (
        <p
            className={cn(
                `mt-4 max-w-screen-lg text-pretty px-4 text-base text-gray-500 sm:whitespace-pre-line ${
                    align === 'center' ? 'mx-auto text-center' : 'text-left'
                }`,
                className,
            )}
            data-cy="subtitle"
        >
            {children}
        </p>
    );
};

export default Subtitle;
