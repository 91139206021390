// Utils
import Head from 'next/head';
import Script from 'next/script';
import ErrorBoundary from '../modules/core/errors/ErrorBoundary';
import { appWithTranslation } from 'next-i18next';
import { SpeedInsights } from '@vercel/speed-insights/next';

// Hooks
import { useInitializeCurrency } from '@app/account/hooks/useInitializeCurrency';

// UI
import Banner from '../modules/app/banner/components/Banner';
import '../styles/globals.css';

// Redux
import { wrapper } from '@core/redux/store';

// Types
import type { AppProps } from 'next/app';

function App({ Component, pageProps }: AppProps) {
    useInitializeCurrency();

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
            </Head>

            <ErrorBoundary>
                <Banner />
                <Component {...pageProps} />
            </ErrorBoundary>

            <SpeedInsights sampleRate={0.01} />
        </>
    );
}

export default wrapper.withRedux(appWithTranslation(App));
