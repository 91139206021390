export const NAME = 'account';

export const AUTH_TOKEN_COOKIE = 'perspective_auth_token';
export const REFRESH_TOKEN_COOKIE = 'perspective_refresh_token';

export const WELCOME_PAGE_SLUG = '/surveys/onboarding/welcome';
export const SHARED_FUNNEL_SAVE_SLUG = '/shared/:id/save';
export const NEXT_APP_SETTINGS_PLANS_YEARLY_URL = '/settings/plan?timeframe=yearly';
export const NEXT_APP_SETTINGS_PAYMENT_MODAL_URL = '/settings?modal=change-payment-method';
export const APP_URL = 'https://app.perspective.co';
export const CRM_USER_LOGIN_URL = process.env.NEXT_PUBLIC_CRM_USER_LOGIN_URL;

export const SUBSCRIPTION_ERROR_CODES = {
    INSUFFICIENT_FUNDS: 'insufficient_funds',
    STRIPE_AUTH_FAILED: 'stripe_auth_failed',
    GENERIC_DECLINE: 'generic_decline',
    SUBSCRIPTION_ERROR: 'subscription-error',
    UPDATE_SUBSCRIPTION_ERROR: 'update-subscription-error',
    PAYPAL_ERROR: 'paypal-error',
    INSUFFICIENT_FUNDS_PAYPAL: 'insufficient_funds_paypal',
};

export const PASSWORD_MAX_COMPLEXITY_LEVEL = 4;

export const DEFAULT_STRIPE_AUTH_AMOUNT = 500; // $5

export const enum SUBSCRIPTION_STATUS {
    InTrial = 'in_trial',
    Cancelled = 'cancelled',
    NonRenewing = 'non_renewing',
}

// Currency
export const COUNTRY_SS_KEY = 'country';
export const CURRENCY_SS_KEY = 'currency';
export const GREAT_BRITAIN_COUNTRY_KEY = 'GB';
export const EUROPE_CONTINENT_KEY = 'EU';
