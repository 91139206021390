/* CAUTION: Imgix does not support animated gifs (yet) */

export const getImgixUrl = (path: string) => {
    const cleanPath = path.replace(/^\/$/g, '');

    return `${process.env.NEXT_PUBLIC_IMGIX_CDN || '/'}${cleanPath}`;
};

export const imgix = {
    flags: {
        us: getImgixUrl('assets/app/templateStore/usflagemoji.png'),
        de: getImgixUrl('assets/app/templateStore/deflagemoji.png'),
    },
    bundles: {
        journeysBusinessBundle: getImgixUrl(
            'assets/checkout/bundles/journeys-business-bundle-cover.png',
        ),
        journeysAdvancedBundle: getImgixUrl(
            'assets/checkout/bundles/journeys-advanced-bundle-cover.png',
        ),
        funnelMonthVSL: getImgixUrl('assets/checkout/bundles/funnel-month-vsl.png'),
        instantVSL: getImgixUrl('assets/checkout/bundles/instant-vsl.png'),
        recruitingVSL: getImgixUrl('assets/checkout/bundles/recruiting-vsl.png'),
        starterAdvanced: getImgixUrl('assets/checkout/bundles/starter-advanced.png'),
        starterBusiness: getImgixUrl('assets/checkout/bundles/starter-business.png'),
        perspectiveNeon: getImgixUrl('assets/checkout/bundles/perspective-neon.png'),
        starterBundleBusiness: getImgixUrl('assets/checkout/bundles/starter-bundle-business.png'),
        starterBundleAdvanced: getImgixUrl(
            'assets/checkout/bundles/starter-bundle-advanced-new.png',
        ),
    },
    shared: {
        sharedVideoBg: getImgixUrl('assets/checkout/shared/shared-video-bg.png'),
        moneyBackGuaranteeShieldCheck: getImgixUrl('assets/checkout/shared/shield-check.png'),
        // Logos
        trustpilot: getImgixUrl('assets/checkout/shared/trustpilot.png'),
        g2: getImgixUrl('assets/checkout/shared/reversed-g2@2x.webp'),
        capterra: getImgixUrl('assets/checkout/shared/capterra.png'),
        // Avatars used in Testimonials
        avatarShaanPuri: getImgixUrl('assets/checkout/shared/shaan-puri.png'),
        avatarSpencerBrickman: getImgixUrl('assets/checkout/shared/spencer-brickman.png'),
        avatarNiklasBuschner: getImgixUrl('assets/checkout/shared/niklas-buschner.png'),
        avatarAsherPouliot: getImgixUrl('assets/checkout/shared/asher-pouliot.png'),
        avatarStefanZangerle: getImgixUrl('assets/checkout/shared/stefan-zangerle.png'),
        avatarJakobStrehlow: getImgixUrl('assets/checkout/shared/jakob-strehlow.png'),
        avatarFranzMerz: getImgixUrl('assets/checkout/shared/franz-merz.png'),
        avatarFlorianEdlinger: getImgixUrl('assets/checkout/shared/florian-a-edlinger.png'),
        avatarNicolasMeining: getImgixUrl('assets/checkout/shared/nicolas-meining.png'),
    },
};

export default imgix;
