import { NAME } from '../constants';

// Utils
import Router from 'next/router';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { identifyUser } from '@core/tracking';
import { apiPost, apiPatch, handleRuntimeError } from '@core/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPostSignupUrl, setTokensFromResponse } from '../utils';

// Redux
import { setUser } from '@app/account/models/user';
import { getCountry } from '@app/pricing/models/pricing';
import { getIsPartnerValid, getPartnerKey } from './referral';
import { getCouponIds, getIsCouponValid, getTrialEnd } from './coupon';

// Types
import type {
    Account,
    AccountWithPlan,
    CreateAccountArgs,
    CreateSubscriptionArgs,
    UpdateSubscriptionArgs,
} from '../types';
import type { AppThunk } from '@core/redux/store';

interface State {
    loading: boolean;
    error: { statusCode: number; message: string };
    status: number;
    account: AccountWithPlan;
}

const initialState: State = {
    loading: false,
    error: null,
    status: null,
    account: {
        name: '',
        email: '',
        password: '',
        planId: '',
    },
};

export const signupSlice = createSlice({
    name: `${NAME}-signup`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setError(state, action: PayloadAction<State['error']>) {
            return {
                ...state,
                error: action.payload,
            };
        },
        setStatus(state, action: PayloadAction<number>) {
            return {
                ...state,
                status: action.payload,
            };
        },
        setAccount(state, action: PayloadAction<Account>) {
            return {
                ...state,
                account: {
                    planId: state.account.planId,
                    ...action.payload,
                },
            };
        },
        setAccountPlanId(state, action: PayloadAction<string>) {
            return {
                ...state,
                account: {
                    ...state.account,
                    planId: action.payload,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setAccount, setAccountPlanId, setLoading, setError } = signupSlice.actions;

// === Thunks ======

export const createAccount =
    ({ account, query, locale, redirectUrl }: CreateAccountArgs): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            dispatch(setError(null));

            const payload = {
                email: account.email,
                password: account.password,
                language: locale,
            };

            const response = await (
                await fetch('/api/user/signup', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                })
            ).json();

            if (response?.data?.error) {
                throw response.data;
            }

            setTokensFromResponse(response);

            dispatch(setLoading(false));
            dispatch(setAccount({ ...account, planId: query.planId }));

            // Monitoring & Tracking
            const userData = {
                id: response?.data?.data?.attributes?.trackingId,
                email: response?.data?.data?.attributes?.email,
                name: account.name,
                firstName: account.name.split(' ')[0],
                lastName: account.name.split(' ').slice(1).join(' '),
                language: response?.data?.data?.attributes?.language,
                lists: [
                    {
                        id: 1,
                        status: 'active',
                    },
                ],
            };

            identifyUser(userData);
            dispatch(setUser(userData));

            // Redirect
            const nextStepUrl = getPostSignupUrl(redirectUrl, query);

            await Router.push(nextStepUrl);
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to create an account' });
            dispatch(setError(err.statusCode ? err : { message: err.message }));
            dispatch(setLoading(false));
        }
    };

export const createSubscription =
    ({
        gateway,
        paymentToken,
        firstName,
        lastName,
        address,
        addons,
    }: CreateSubscriptionArgs): AppThunk =>
    async (_, getState) => {
        const state = getState();
        const account = getAccount(state);
        const partnerKey = getPartnerKey(state);
        const isPartnerValid = getIsPartnerValid(state);
        const couponIds = getCouponIds(state);
        const isCouponValid = getIsCouponValid(state);
        const trialEnd = getTrialEnd(state);
        const country = getCountry(state);

        return await apiPost('/subscription', {
            data: {
                planId: account?.planId,
                paymentToken,
                gateway,
                email: account?.email,
                firstName,
                lastName,
                ...(!isEmpty(addons) ? { addons } : {}),
                ...(couponIds && isCouponValid
                    ? { coupon: typeof couponIds === 'string' ? [couponIds] : couponIds }
                    : {}),
                ...(partnerKey && isPartnerValid
                    ? {
                          customerMetaData: {
                              partner_key: partnerKey,
                          },
                      }
                    : {}),
                country: address?.country?.toUpperCase() || country,
                address,
                ...(isNumber(trialEnd) ? { trialEnd } : {}),
                metaData: {},
            },
        });
    };

export const updateSubscription =
    ({ forceTermReset, planId, subscriptionId, addons }: UpdateSubscriptionArgs): AppThunk =>
    async (_, getState) => {
        const state = getState();
        const account = getAccount(state);
        const couponIds = getCouponIds(state);
        const isCouponValid = getIsCouponValid(state);
        const trialEnd = getTrialEnd(state);

        return await apiPatch('/update-subscription', {
            data: {
                email: account?.email,
                subscriptionId,
                planId,
                forceTermReset,
                reactivate: true,
                ...(!isEmpty(addons) ? { addons } : {}),
                ...(isNumber(trialEnd) ? { trialEnd } : {}),
                ...(couponIds && isCouponValid
                    ? { coupon: typeof couponIds === 'string' ? [couponIds] : couponIds }
                    : {}),
            },
        });
    };

// === Selectors ======

export const getAccount = (state): AccountWithPlan => state[NAME]?.signupReducer?.account;
export const getLoading = (state): boolean => state[NAME]?.signupReducer?.loading;
export const getError = (state): State['error'] => state[NAME]?.signupReducer?.error;

export default signupSlice.reducer;
