import type { Relationship, Resource } from '@core/api/types';

export interface SignupValidationErrors {
    name?: string;
    email?: string;
    password?: string;
    confirm?: string;
    consent?: string;
}

export interface CheckoutValidationErrors {
    name?: string;
    card?: string;
}

export interface LoginValidationErrors {
    email?: string;
    password?: string;
}

export interface SignupFormValues {
    name?: string;
    email?: string;
    password?: string;
    confirm?: string;
    consent?: boolean;
}

export interface LoginFormValues {
    email?: string;
    password?: string;
    rememberMe?: boolean;
}

export interface Account {
    name: string;
    email: string;
    password: string;
    planId?: string;
}

export interface AccountWithPlan extends Account {
    planId: string;
}

export interface CreateAccountArgs {
    account: Account;
    query: {
        planId?: string;
        coupon?: string;
        campaignId?: string;
    };
    locale?: string;
    redirectUrl?: string;
}

export interface CreateSubscriptionArgs {
    gateway: PaymentMethod;
    paymentToken: string;
    firstName: string;
    lastName: string;
    address: Address;
    addons?: ChargebeeAddon[];
}

export interface UpdateSubscriptionArgs {
    planId: string;
    subscriptionId: string;
    addons?: ChargebeeAddon[];
    forceTermReset?: boolean;
}

export interface LoginArgs extends LoginFormValues {
    successRedirectUrl?: string;
    welcomeUrl?: string;
    isBundle?: boolean;
    query: {
        planId?: string;
        coupon?: string;
        campaignId?: string;
    };
}

export type PaymentMethod = 'stripe' | 'paypal';

export interface Address {
    line1?: string;
    line2?: string;
    city?: string;
    country?: string;
    state?: string;
    zip?: string;
    phone?: string;
}

export interface PayPalCheckoutDetails {
    COUNTRYCODE?: string;
    SHIPTOSTREET?: string;
    SHIPTOCITY?: string;
    SHIPTOSTATE?: string;
    SHIPTOZIP?: string;
}

export interface ChargebeeAddon {
    id: string;
    quantity: number;
}

export interface GeoLocation {
    continent: string;
    country: string;
    state: string;
    stateName: string;
}

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP',
}

export interface SubscriptionAddon {
    id: string;
    quantity: number;
    amount: number;
    unitPrice: number;
    object: string;
}

export interface SubscriptionAttributes {
    plan_id: string;
    plan_quantity: number;
    plan_unit_price: number;
    billing_period: number;
    billing_period_unit: string;
    trial_end: number;
    customer_id: string;
    plan_amount: number;
    plan_free_quantity: number;
    status: string;
    trial_start: number;
    next_billing_at: number;
    created_at: number;
    started_at: number;
    updated_at: number;
    has_scheduled_changes: boolean;
    channel: string;
    object: string;
    currency_code: string;
    due_invoices_count: number;
    current_term_end: number;
    cancelled_at: number;
    addons: SubscriptionAddon[];
}

export interface SubscriptionRelationships {
    company: Relationship;
}

export type SubscriptionResource = Resource<SubscriptionAttributes, SubscriptionRelationships>;
